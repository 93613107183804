@import './variables';

.ant-carousel {
  background-image: url('../images/bg-h.jpg');
  text-align: center;
  background-size: cover;
  background-position: 18% 100%;
  overflow: hidden;
  width: 100%;
  height: 40vh;

  .slick-slide img {
    display: inherit;
  }
}

.base-home {
  height: 1vh;
  background-color: #a6d1f4;
}

.base-home-2 {
  height: 1vh;
  background-color: #15395f;
}

.bg-home {
  background-color: #6fb4ef;
}

.hm-bg {

  .ant-carousel {
    height: 60vh;
  }

  .image-container {
    width: auto !important;
    height: 60vh;
  }

}



.live-player {
  width: 100%;
  height: 180px;
}

.user_info {
  h1 a {
    font-size: 22px;
    font-weight: 600;
    color: $black;
  }
}

.ant-btn {
  background-color: rgb(213, 183, 148);
  color: $black !important;
  font-size: 15px;
}



.white-text {
  color: $white;
}

.helper-slide-1,
.helper-slide-2,
.helper-slide-3 {
  display: inline-block;
  height: 83%;
  vertical-align: middle;
}

@media (max-width: 550px) {
  .helper-slide-1 {
    height: 74%;
  }

  .helper-slide-2 {
    height: 53%;
  }

  .helper-slide-3 {
    height: 65%;
  }
}

@media (min-width: 1850px) {

  .helper-slide-1,
  .helper-slide-2,
  .helper-slide-3 {
    height: 75%;
  }
}

.image-container img {
  vertical-align: middle;
}

@media (min-width: 550px) {
  .slide-img {
    width: 80%;
  }
}

@media (max-width: 550px) {
  .slide-img {
    width: 54%;
  }
}

.youtube-text {
  color: red;
  font-weight: 700;
}

.slider {
  width: 100%;

}

.header {
  background: rgba(248, 223, 220, 0.7);
  padding: 95px 30px 95px 30px;
}

.live-text a {
  font-size: 11px;
  display: block;
}

.display-none {
  display: none;
}

/* Button CSS */
.btn-center .ant-col-xs-24 {
  text-align: center;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 20px;
}

.mrl-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mt-45 {
  margin-top: 45px;
}

.mt-55 {
  margin-top: 55px;
}

.pd-30 {
  padding: 30px;
}

.pd-10 {
  padding: 10px;
}

.pd-20 {
  padding: 20px;
}

.pd-60 {
  padding: 60px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.live-broadcast {
  text-align: center;
  margin-top: 20px;
}

// @media (max-width: 768px) {
//   .live-broadcast {
//     margin-top: 20px;
//   }
// }

// @media (min-width: 768px) {
//   .live-broadcast {
//     margin-top: 20px;
//   }
// }
/* @media (max-width: 500px) {
  .image-container img{
  margin-top: 35px;
  width: 80%;
}
}

@media (max-width: 375px) {
  .image-container img{
  margin-top: 50px;
}
}

@media (max-width: 360px) {
  .image-container img{
  margin-top: 30px;
}
} */


// .bg-color {
//   /*background-color: #6772e5;*/
//   background-color: #142C98;
// }

/* .ant-carousel .slick-slider {
  top: -9px;
} */

/* .ant-carousel .slick-slide h1 {
  color: rgb(242, 227, 180)!important;
  padding-top: 35vh;
} */