@import './variables';

.mr-0 {
  margin-right: 0 !important;
}

.header-text {
  font-size: 55px;
  margin-top: 110px;
}

.urdu-heading {
  font-size: 2.8em;
  font-weight: normal!important;
}

.urdu-text {
  font-size: 1.3em;
  text-align: right;
  font-weight: normal;
  line-height: 38px;
}

.urdu-main-heading {
  font-size: 4.3em !important;
  font-weight: normal !important;
}

.urdu-btn {
  font-size: 1.2em !important;
  font-weight: normal !important;
}

.urdu-link {
  color: red !important;
}


/* Btn CSS */
.ant-btn {
  transition: all .2s ease-out;
  background: #0e278e;
  color: $white !important;
  border-color: transparent;
  border-radius: 4px;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 600;
  padding: 0 23px;

  &:hover,
  &:focus {
    border-color: #d5b794;
    color: $black !important;
    background: $white;
  }
}


/* Ant Card */
.ant-card {
  font-size: 16px !important;
  border-radius: 8px;
  box-shadow: 5px 3px 7px 1px rgba(0, 0, 0, 0.15);

    p {
      font-size: 20px;
    }
}

.ant-card-bordered {
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.ant-card-hoverable {
  cursor: default;
}

.ant-card-body {
  padding: 14px;
}

/* Text-center */
.text-center {
  text-align: center;
  width: 100%;
}

/*Plyr CSS*/
.plyr__volume {
  align-items: center;
  display: flex;
}

.plyr--audio .plyr__controls {
  padding: 10px;
}

.plyr__volume input[type=range] {
  margin-left: 5px;
}

@media (min-width:480px) {
  .plyr__volume {
    max-width: 90px;
    margin-left: 10px;
  }

  .plyr__controls {
    margin-left: 10px;
  }
}

.plyr {
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  margin: 20px auto;
}

@media (min-width:768px) {
  .plyr__volume {
    max-width: 110px;
    margin-left: 10px;
  }

  .plyr__controls {
    margin-left: 5px;
  }
}

.plyr__control {
  border-radius: 1px;
  margin-left: 0 !important;
}

.plyr--audio .plyr__controls {
  padding: 0 10px 0 0;
}

@media (min-width: 768px) {
  .plyr__controls {
    margin-left: 0px;
  }
}

@media (min-width: 480px) {
  .plyr__controls {
    margin-left: 0px;
  }
}

/* Player Colors */

.plyr--full-ui input[type=range] {
  color: #d5b794;
}

.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded=true] {
  background: #ece2d0;
  color: $white;
}

/* Pagination CSS */

.ant-pagination {
  text-align: center;
}

.ant-pagination-item {
  border-radius: 0;
  border: 1.5px solid #0e278e;
  border-radius: 3.5px;
  background-color: #0e278e;
  color: $white;
  margin: 5px 7px;

  &:hover {
    background-color: $white;
    color: #0e278e;
  }

  &:hover,
  &:focus {
    border-color: #0e278e !important;
  }

  a {
    color: $white !important;
  }

  &:hover a {
    color: #0e278e !important;
  }

  &:focus a {
    color: $white !important;
  }
}

.ant-pagination-item-active {
  background: $white !important;
  border-color: #0e278e !important;

  a {
    color: #0e278e !important;
  }

  &:hover a,
  &:focus a {
    color: #0e278e !important;
  }
}


.cookiePolicy {
  display: none;
}
/* Text-center */

// .text-center {
//   text-align: center;
// }


/* Search Bar Styles */

// .searchBar.ant-input {
//   border: 1px solid #bfbfbf;
//   color: #0e278e;
//   font-size: 18px;
// }

// .searchBar.ant-input:focus {
//   border-color: #0e278e;
//   outline: 0;
//   box-shadow: 0 0 0 2px rgba(14, 39, 142, 0.1);
//   border-right-width: 1px !important;
// }

// .searchBar.ant-input:hover {
//   border-color: #0e278e;
//   border-right-width: 1px !important;
// }

// .searchBar.ant-input::placeholder {
//   font-size: 18px;
//   color: $black;
// }