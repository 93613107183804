@import './variables';

.sd-header {
  .ant-carousel {
    background-image: url('../images/sun-bg.jpg');
    background-position: 65% 50%;
    height: 65vh;
  }

  .header-text {
    font-size: 60px;
    color: #174403;
    text-shadow: 1px 1px 0px #49ff12;
    margin-right: 25vw;

  }

  .header-text.urdu-main-heading {
    font-size: 7em!important;
  }
}

.hairline {
  height: 1vh;
  background-color: #8aa115;
}

@media (max-width: 500px) {
  .sd-header {
    .header-text {
      font-size: 40px;
      margin-top: 18vh;
      margin-right: 29vw;
    }

    .header-text.urdu-main-heading {
      font-size: 4.3em!important;
    }

    .ant-carousel {
      height: 65vh;
    }
  }
}

.white {
  color: $white;
}

.sd-color {
  background-color: #7a9814;
  padding-bottom: 30px;

  .ant-card {
    background-color: #cde285;
    color: $black;
  }

  .ant-btn {
    background-color: $sd-accent;
    color: $white !important;

    &:hover,
    &:focus {
      border-color: $sd-accent;
      color: $black !important;
      background: $white;
    }
  }

  .link-btn {
    border: 0;
    background: transparent;
    color: $black !important;
    padding: 0 0;

    &:hover,
    &:focus {
      border: 0;
      background: transparent;
      color: $white !important;
      padding: 0 0;
    }
  }

  .ant-pagination-item {
    border-radius: 0;
    border: 1.5px solid $sd-accent;
    border-radius: 2.5px;
    background-color: $sd-accent;
    color: $white;
    margin: 5px 7px;

    &:hover {
      background-color: $white;
      color: $sd-accent;
    }

    a {
      color: $white !important;
    }

    &:hover a {
      color: $sd-accent !important;
    }

    &:focus a {
      color: $sd-accent !important;
    }

    &:hover,
    &:focus {
      border-color: $sd-accent !important;
    }
  }

  .ant-pagination-item-active {
    background: $white !important;
    border-color: $sd-accent !important;

    a {
      color: $sd-accent !important;
    }

    &:hover a,
    &:focus a {
      color: $sd-accent !important;
    }
  }

  .plyr--full-ui input[type=range] {
    color: $sd-accent;
  }

  .plyr--audio .plyr__control.plyr__tab-focus,
  .plyr--audio .plyr__control:hover,
  .plyr--audio .plyr__control[aria-expanded=true] {
    background: $sd-accent;
    color: $white;
  }
}