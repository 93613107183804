.short-clips {
    margin-top: 10vh;
}

.short-clips * {
    color: #444;
}


.grid {
    display: grid;
    gap: 20px;
    margin: 40px 16px;

    article {
        padding: 1rem;

        h3 {
            font-size: 1.2rem;
        }

        p {
            color: #606060;
            font-size: 0.9rem;
            font-weight: 400;
        }
    }
}

.aspect-ratio {
    position: relative;
    max-width: 100%;

    &::before {
        height: 0px;
        content: "";
        display: block;
        padding-bottom: 56.25%;
    }

    &>*:not(style) {
        position: absolute;
        overflow: hidden;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    iframe {
        border: 0;
    }
}

@media screen and (min-width: 48em) {
    .grid {
        grid-template-columns: repeat(3, minmax(0px, 1fr));
    }
}


@media screen and (min-width: 30em) {
    .grid {
        grid-template-columns: repeat(3, minmax(0px, 1fr));
    }
}
