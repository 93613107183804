@import './variables';

.by-header {
	.ant-carousel {
		background-image: url('https://media.giphy.com/media/AlqUdr1CSODLO/giphy.gif');
		background-position: 40% 0%;
		height: 65vh;
	}

	.header-text {
		color: #1a481d;
		text-shadow: 1px 1px 0px #57ff64;
		margin-top: 50vh;
		font-size: 60px;
	}

	.header-text.urdu-main-heading {
		font-size: 7em!important;
		margin-top: 45vh;
	}
}

.bg-bayan {
	background-color: #c5d289;
	padding-bottom: 30px;
}

.base-bayan {
	height: 1vh;
	background-color: #d0d3b8;
}

.base-bayan-2 {
	height: 1vh;
	background-color: #4d632a;
}

.fill-height {
	height: 60vh;
}

.header-text {
    margin-top: 80px;
}

@media (max-width: 500px) {
	.by-header {
		.ant-carousel {
			height: 65vh;
		}
		.header-text {
			margin-top: 40vh;
		}
		.header-text.urdu-main-heading {
			margin-top: 40vh;
			font-size: 4.3em!important;
		}
	}
}

@media (max-width: 500px) {
	.fill-height {
		height: 70vh;
	}

}

@media (min-width: 550px) {
	.image-container .slide3 {
		width: 40% !important;
	}
}

.bayan-btn {
	margin-top: 40px;
	box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.35);
	background: #748c4e69;
	color: #ffffff !important;
	width: 222px;
	text-shadow: 1px 1px 0px #230a0a;

	&:first {
		margin-top: 20px;
	}
}

.btn-center {
	text-align: center;
}


.link-btn {

	&:hover,
	&:focus {
		border: 0;
		background: transparent;
		color: $white !important;
	}
}

.ant-pagination-next {
	margin-left: 8px;
}

table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
}

.row-center {
	text-align: center;
}