.footer {
    // position: absolute;
    // right: 0;
    // left: 0;
    // padding: 1rem;
    // background-color: #dddddd;
    // text-align: left;
    // align-items: center;
    // height: 70px;
    background-color: #081220;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        color: #ffffff;
    }
}