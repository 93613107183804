@import './variables';

@font-face {
  font-family: 'Jameel Noori Nastaleeq';
  src: url("../styles/fonts/JameelNoori/JameelNooriNastaleeq.ttf");
  // src: url("../styles/Fajer-Noori-Nastalique.eot") format("embedded-opentype"), 
  //      url("../styles/fonts/urdu-web-fonts-master/Fajer-Noori-Nastalique/fonts/Fajer-Noori-Nastalique.woff") format("woff"), 
  //      url("../styles/fonts/urdu-web-fonts-master/Fajer-Noori-Nastalique/fonts/Fajer-Noori-Nastalique.ttf") format("truetype"), 
  //      url("../styles/fonts/urdu-web-fonts-master/Fajer-Noori-Nastalique/fonts/Fajer-Noori-Nastalique.svg") format("svg");
  unicode-range: U+0600-06FF, U+0750-077F, U+FB50-FDFF, U+FE70-FEFF;
}

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100% !important;
}

body {
  margin: 0;
  padding: 0;
  font-size: 22px !important;
  line-height: 30px !important;
  font-family: 'Jameel Noori Nastaleeq', 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  height: 100% !important;
  max-height: 100% !important;
  color: $white !important;
}

@media (max-width: 500px) {
  body {
    font-size: 19px !important;
  }
}

.black-text {
  color: $black;
}

h1 {
  font-size: 35px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600!important;
}

button,
input {
  font-size: inherit;
  font-family: 'Jameel Noori Nastaleeq', 'Source Sans Pro', sans-serif !important;
}

ul {
  margin-top: 16px;
}