@import './variables';

.bj-header .ant-carousel {
    background-image: url('../images/bj.jpg');
    background-position: 83% 85%;
    height: 65vh;
}

.bj-header-text {
    color: #3a1a27;
    font-size: 60px;
    margin-left: 45vw;
    margin-top: 23vh;
    text-shadow: 1px 1px 0px #dea1c6;
}

.bj-header-text.urdu-main-heading {
    font-size: 7em!important;
}

.base-bj {
    height: 1vh;
    background-color: #e5d7ce;
}

.base-bj-2 {
    height: 1vh;
    background-color: #a57786;
}

@media (max-width: 500px) {
    .bj-header-text {
        font-size: 40px;
        margin-top: 3vh;
        margin-right: 0;
        margin-left: 0;
    }
    .bj-header-text.urdu-main-heading {
        font-size: 4.3em!important;
    }


    .bj-header .ant-carousel {
        height: 65vh;
    }
}

.ant-pagination-prev,
.ant-pagination-next {
    button {
        background: transparent !important;
        color: $white !important;
    }
}

.bj-bg {
    background-color: #d1809a;
    height: auto;
    padding-bottom: 30px;

    .ant-card {
        background-color: #e0a5b8;
        border: 0;
        color: $black;

        p {
            font-size: 20px;
            color: $black;
        }
    }

    .ant-btn {
        background-color: $bj-accent;
    }

    .ant-pagination-item {
        background-color: $bj-accent;
        border-color: $bj-accent;
        border: 1px solid $bj-accent;

        a {
            color: $black !important;
        }

        &:hover,
        &:focus {
            border-color: $bj-accent !important;
            background-color: $white;
        }
    }

    .ant-pagination-item-active {
        border-color: $bj-accent !important;
        background-color: $white;
    }


    .plyr--audio .plyr__control.plyr__tab-focus,
    .plyr--audio .plyr__control:hover,
    .plyr--audio .plyr__control[aria-expanded=true] {
        background: $bj-accent;
        color: $black;
    }

    .plyr--full-ui input[type=range] {
        color: $bj-accent;
    }

    .ant-pagination-next .ant-pagination-item-link {
        background-color: transparent !important;
        border: 1px solid $bj-accent;
        color: $black !important;
    }

    .ant-pagination-disabled {

        a,
        &:hover a,
        &:focus a,
        .ant-pagination-item-link,
        &:hover .ant-pagination-item-link,
        &:focus .ant-pagination-item-link {
            border: 1px solid $bj-accent;
            color: $black !important;
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next {

        .ant-pagination-item-link {
            color: $black !important;
            background-color: $bj-accent;
        }

    }

    .plyr--audio .plyr__control.plyr__tab-focus,
    .plyr--audio .plyr__control:hover,
    .plyr--audio .plyr__control[aria-expanded=true] {
        background: $bj-accent;
        color: $black;
    }

    .plyr--full-ui input[type=range] {
        color: $bj-accent;
    }

    .plyr--audio .plyr__control.plyr__tab-focus,
    .plyr--audio .plyr__control:hover,
    .plyr--audio .plyr__control[aria-expanded=true] {
        background: $bj-accent !important;
        color: $black;
    }

}

/* .search-container {
    width: 300px;
    margin: 0 auto;
}

.searchBar.ant-input {
    background-color: rgb(255, 228, 154);
}

.searchBar.ant-input:focus {
    border-color: #fde092;
} */