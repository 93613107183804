.poster-card {
    width: 400px;
}

@media (max-width: 500px) {
    .poster-card {
        width: 100%;
    }
}

.pt-background {
    background-color: #d9edff;

    h1 {
        color: #15334e;
    }

    .poster-card {
        box-shadow: 4px 7px 5px 4px rgba(0, 0, 0, 0.15);
    }
}

.base-poster {
    height: 1vh;
    background-color: #d2d1d1d9;
}