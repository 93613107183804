@import './variables';

nav {
      padding-top: 0;
      margin-bottom: 55px;
      height: 100%;

      ul {
            li {
                  a {
                        display: inline-block;
                        outline: none;
                        color: $black !important;
                        text-transform: uppercase;
                        text-decoration: none;
                        font-size: 16px;
                        letter-spacing: 1.2px;
                        font-weight: 700;
                        position: relative;
                        transition: color .1s ease;

                        &:focus,
                        &:hover,
                        &:active {
                              text-decoration: none;
                              color: $black;
                        }

                        &::before {
                              content: '';
                              position: absolute;
                              bottom: -1px;
                              left: 0;
                              width: 100%;
                              height: 1px;
                              /* background: #0e278e; */
                              background: $black;
                              opacity: .2;
                              transform: scaleX(0);
                              transform-origin: right;
                              transition: transform .7s cubic-bezier(.19, 1, .22, 1), .6s ease;
                        }
                  }

                  &:hover a::before,
                  &:active a::before {
                        transform: scaleX(1);
                        transform-origin: left;
                        opacity: .8;
                  }

                  &:not(:first-child) {
                        margin-left: 48px;
                  }

                  &:last-child {
                        margin-left: 48px;
                        margin-right: 10px;
                  }
            }
      }
}

.logo {
      float: left;
      top: 28px;
      left: 0px;
      position: relative;
      z-index: 1000;
      margin-left: 20px;

      a {
            color: #0e278e;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 23px;
            letter-spacing: 0px;
            text-decoration: none;

            img {
                  width: calc((100vw - 50px * 2) * 3 / 12);
            }
      }
}

@media (max-width: 500px) {
      .logo a img {
            width: 50%;
      }
}

.nav-wrapper {
      ul {
            float: right;
            padding-top: 85px;
            padding-inline-start: 0;

            li {
                  display: inline-block;
                  float: left;
                  padding-left: 20px;

                  &:last-child a {
                        font-size: 22.5px;

                        &::before {
                              display: none;
                        }

                        &:hover {
                              color: red;
                        }
                  }
            }
      }
}

#lang-bar {
      background-color: #ffffff;
      height: 35px;
      text-align: right;
}

.lang-btn {
      background: transparent;
      color: black !important;
      border: 0;
      font-size: 15px;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0);
      text-decoration: underline;
      height: auto;
      line-height: 1;
      font-weight: normal!important;
      z-index: 1001;
      &:hover,
      &:focus {
            background: transparent;
            color: black !important;
            border-color: #ffffff;
      }

      &:last-child {
            font-size: 1.3em;
            margin-right: 1%;
      }
}

.urdu-menu {
      font-size: 1.3em;
      text-align: right;
      font-weight: normal;
}

@media screen and (max-width: 864px) {
      .logo {
            padding: 0;
      }

      .nav-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: #54494a;
            opacity: 0;
            transition: all 0.2s ease;

            ul {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 100%;

                  li {
                        display: block;
                        float: none;
                        width: 100%;
                        text-align: center;
                        margin-bottom: 10px;

                        &:last-child a {
                              font-size: 45px;
                        }

                        &:not(:first-child) {
                              margin-left: 0;
                        }

                        a {
                              padding: 10px 24px;
                              opacity: 0;
                              color: $white !important;
                              font-size: 2.5rem;
                              text-shadow: 3px 4px 0px black;
                              letter-spacing: 1.2px;
                              line-height: 1.2;
                              transform: translateX(-20px);
                              transition: all 0.1s ease;
                        }
                  }
            }
      }

      /* .nav-wrapper ul li:nth-child(1) a {
          transition-delay: 0.2s;
    }

    .nav-wrapper ul li:nth-child(2) a {
          transition-delay: 0.3s;
    }

    .nav-wrapper ul li:nth-child(3) a {
          transition-delay: 0.4s;
    }

    .nav-wrapper ul li:nth-child(4) a {
          transition-delay: 0.5s;
    }

    .nav-wrapper ul li:nth-child(5) a {
      transition-delay: 0.6s;
      }

      .nav-wrapper ul li:nth-child(6) a {
            transition-delay: 0.7s;
      }

      .nav-wrapper ul li:nth-child(7) a {
            transition-delay: 0.8s;
          }

    .nav-wrapper ul li:nth-child(8) a {
      transition-delay: 0.9s;
    } */

      .nav-btn {
            // position: fixed;
            // right: 10px;
            // top: 6%;
            // display: block;
            // width: 48px;
            // height: 48px;
            // cursor: pointer;
            // z-index: 1000001;
            // border-radius: 50%;
            position: absolute;
            right: 20px;
            top: 30%;
            display: block;
            width: 50px;
            height: 50px;
            cursor: pointer;
            z-index: 1000001;
            border-radius: 50%;

            i {
                  display: block;
                  width: 20px;
                  height: 2px;
                  background-color: $black;
                  border-radius: 2px;
                  margin-left: 14px;

                  &:nth-child(1) {
                        margin-top: 16px;
                  }

                  &:nth-child(2) {
                        margin-top: 4px;
                        opacity: 1;
                  }

                  &:nth-child(3) {
                        margin-top: 4px;
                  }
            }
      }
}

#nav:checked+.nav-btn {
      transform: rotate(45deg);

      i {
            background: $white;
            transition: transform 0.45s ease;

            &:nth-child(1) {
                  transform: translateY(6px) rotate(180deg);
            }

            &:nth-child(2) {
                  opacity: .9;
            }

            &:nth-child(3) {
                  transform: translateY(-6px) rotate(90deg);
            }
      }
}

#nav:checked~.nav-wrapper {
      z-index: 9990;
      opacity: 1;

      ul li a {
            opacity: 1;
            transform: translateX(0);
      }
}

#nav:checked~.logo {
      display: none;
}

.hidden {
      display: none;
}