.bk-header .ant-carousel {
    background-image: url('../images/bk.jpg');
    background-position: 40% 54%;
    height: 80vh;
}

.base-books {
    height: 1vh;
    background-color: #c5a781;
}

.base-books-2 {
    height: 1vh;
    background-color: #b03327;
}

.bk-background {
    background-color: #ecc896;
    height: 100vh;
}

.bk-header-text {
    color: #000;
    font-size: 60px;
    margin-top: 128px;
}

.books-container {
    width: auto !important;
    height: 80vh;
}

.helper {
    display: inline-block;
    height: 103%;
    vertical-align: middle;
}

.book-img {
    width: 100%;
}


@media (max-width: 786px) {
    .bk-header .ant-carousel {
        height: 80vh;
        background-position: 50% 54%;
    }
}

@media (max-width: 500px) {
    .bk-header-text {
        font-size: 60px;
        margin-top: 100px;
    }

    .bk-header .ant-carousel {
        height: 70vh;
        background-position: 100% 20%;
    }

    .books-container {
        height: 70vh;
    }

    .book-img {
        width: 70%;
    }

    .helper {
        height: 70vh;
    }
}

.image-container img {
    vertical-align: middle;
}