/* Colours */
$black: #000000;
$white: #ffffff;

/* Sunday Bayan */
$sd-accent: #5e750f;

/* Before Juma */
$bj-accent: #c2a3a4;

/* After Juma */
$aj-accent:#a57786;

/* Other Bayans */
$misc-color: #446675;
$pagination-color: #abc6ea;